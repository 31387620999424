/* Grid
-------------------------------------------------------------- */
@function column-calc($num, $spacing:$gutter){
    @return calc(((99.999% / #{$columns}) * #{$num}) - (#{$spacing}));
}
@function column-calc-fallback($num){
    @return ((100 / $columns) * $num) - 5%;
}
@mixin column($num) {
	width: column-calc-fallback($num);
	width: column-calc($num);
}

.row {
    clear:both;
    margin-left:-$gutter;
        &:after {
            content:'';
            display: table;
        }
}
.col {
    max-width:$max-container-width;
	float:left;
    margin-left:#{$gutter};
}

/* Sample column classes, roll your own for your own needs using the column calulation helpers above
Example:
.xsmall-4 {
    @media(max-width:$bp-small){
        @include column(4);
    }
}
.xsmall-5 {
    @media(max-width:$bp-small){
        @include column(6);
    }
}
.xsmall-6 {
    @media(max-width:$bp-small){
        @include column(6);
    }
}
.xsmall-8 {
    @media(max-width:$bp-small){
        @include column(8);
    }
}
.xsmall-11 {
    @media(max-width:$bp-small){
        @include column(11);
    }
}
.xsmall-12 {
    @media(max-width:$bp-small){
        float:none;
        width:auto;
    }
}
@media(min-width:$bp-small){
    .small-3 {
        @include column(3);
    }
    .small-4 {
        @include column(4);
    }
    .small-6 {
        @include column(6);
    }
    .small-8 {
        @include column(8);
    }
    .small-9 {
        @include column(9);
    }
    .small-10 {
        @include column(10);
    }
    .small-11 {
        @include column(11);
    }
    .small-2 {
        @include column(2);
    }
    .small-12 {
        width:100%;
        max-width:$max-container-width;
    }
}
.medium-2 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(2);
    }
}
.medium-3 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(3);
    }
}
.medium-4 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(4);
    }
}
.medium-5 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(5);
    }
}
.medium-6 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(6);
    }
}
.medium-7 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(7);
    }
}
.medium-8 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(8);
    }
}
.medium-9 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(9);
    }
}
.medium-10 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(10);
    }
}
.medium-12 {
    @media (min-width:$bp-medium) {
        margin-left:0;
        float:none;
        width:auto;
    }
}
.large-3 {
    @media (min-width:$bp-large) {
	   @include column(3);
    }
}
.large-4 {
    @media (min-width:$bp-large) {
	   @include column(4);
    }
}
.large-6 {
    @media (min-width:$bp-large) {
	   @include column(6);
    }
}
.large-9 {
    @media (min-width:$bp-large) {
	   @include column(9);
    }
}
.large-12 {
    @media (min-width:$bp-large) {
        margin-left:0;
	    float:none;
        width:auto;
    }
}

//Grid modifiers
.col--centre {
    margin:0 auto;
}
 */