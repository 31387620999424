/* Normalise
-------------------------------------------------------------- */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  background: #fff; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, main {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

strong,
b {
  font-weight: 700; }

em,
i {
  font-style: italic; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

ins {
  text-decoration: none; }

mark {
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

dfn {
  font-style: italic; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

hr {
  border: 0;
  display: block;
  height: 1px;
  margin: 0;
  padding: 0; }

address {
  font-style: normal; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

figure {
  margin: 0; }

[hidden] {
  display: none; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  display: block;
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none; }

a:hover,
a:focus,
a:active {
  text-decoration: none; }

input,
button,
select {
  -moz-appearance: none;
  -webkit-appearance: none; }

input,
button,
select {
  border: 0 none;
  border-radius: 0;
  padding: 0;
  vertical-align: middle; }

select::-ms-expand {
  display: none; }

input[type="search"] {
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none; }

input[type="checkbox"] {
  -moz-appearance: checkbox;
  -webkit-appearance: checkbox; }

input[type="radio"] {
  -moz-appearance: radio;
  -webkit-appearance: radio; }

input[type="range"] {
  -moz-appearance: slider-horizontal;
  -webkit-appearance: slider-horizontal; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-size: 99%;
  line-height: normal;
  margin: 0;
  vertical-align: baseline; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 0 none;
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
input[disabled] {
  cursor: default; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%; }

/* Type
-------------------------------------------------------------- */
body,
button,
input,
select,
textarea {
  font: 400 100% "proxima-nova", sans-serif;
  line-height: 1.5;
  color: #191919; }

input,
select,
textarea {
  font-size: .99rem;
  line-height: 1rem; }

b,
strong {
  font-weight: 700; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.2; }

h1 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem; }

h2 {
  font-size: 1.25rem;
  margin-bottom: 1.5rem; }

h3 {
  font-size: 1rem;
  margin-bottom: 0.75rem; }

h4 {
  font-size: 0.875rem;
  margin-bottom: 0.75rem; }

h5 {
  font-size: 0.75rem;
  margin-bottom: 0.75rem; }

h6 {
  font-size: 0.625rem;
  margin-bottom: 0.75rem; }

p {
  margin-bottom: 0.75rem; }

a,
a:link,
.link {
  color: #191919; }

.no-webfonts:not(.no-js) * {
  font-family: sans-serif; }

/* Grid
-------------------------------------------------------------- */
.row {
  clear: both;
  margin-left: -30px; }
  .row:after {
    content: '';
    display: table; }

.col {
  max-width: 1400px;
  float: left;
  margin-left: 30px; }

/* Sample column classes, roll your own for your own needs using the column calulation helpers above
Example:
.xsmall-4 {
    @media(max-width:$bp-small){
        @include column(4);
    }
}
.xsmall-5 {
    @media(max-width:$bp-small){
        @include column(6);
    }
}
.xsmall-6 {
    @media(max-width:$bp-small){
        @include column(6);
    }
}
.xsmall-8 {
    @media(max-width:$bp-small){
        @include column(8);
    }
}
.xsmall-11 {
    @media(max-width:$bp-small){
        @include column(11);
    }
}
.xsmall-12 {
    @media(max-width:$bp-small){
        float:none;
        width:auto;
    }
}
@media(min-width:$bp-small){
    .small-3 {
        @include column(3);
    }
    .small-4 {
        @include column(4);
    }
    .small-6 {
        @include column(6);
    }
    .small-8 {
        @include column(8);
    }
    .small-9 {
        @include column(9);
    }
    .small-10 {
        @include column(10);
    }
    .small-11 {
        @include column(11);
    }
    .small-2 {
        @include column(2);
    }
    .small-12 {
        width:100%;
        max-width:$max-container-width;
    }
}
.medium-2 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(2);
    }
}
.medium-3 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(3);
    }
}
.medium-4 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(4);
    }
}
.medium-5 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(5);
    }
}
.medium-6 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(6);
    }
}
.medium-7 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(7);
    }
}
.medium-8 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(8);
    }
}
.medium-9 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(9);
    }
}
.medium-10 {
    @media (min-width:$bp-medium) {
        margin-left:$gutter;
        @include column(10);
    }
}
.medium-12 {
    @media (min-width:$bp-medium) {
        margin-left:0;
        float:none;
        width:auto;
    }
}
.large-3 {
    @media (min-width:$bp-large) {
	   @include column(3);
    }
}
.large-4 {
    @media (min-width:$bp-large) {
	   @include column(4);
    }
}
.large-6 {
    @media (min-width:$bp-large) {
	   @include column(6);
    }
}
.large-9 {
    @media (min-width:$bp-large) {
	   @include column(9);
    }
}
.large-12 {
    @media (min-width:$bp-large) {
        margin-left:0;
	    float:none;
        width:auto;
    }
}

//Grid modifiers
.col--centre {
    margin:0 auto;
}
 */
.brand {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 3rem;
  line-height: 1;
  margin: 3rem 0 2rem; }
  .brand a {
    color: #333; }

.navigation {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 2rem; }

.navigation-menu li {
  display: inline-block; }

.navigation-menu a {
  display: block;
  margin: 0 -2px;
  padding: 1rem;
  color: #666; }

.hero {
  background: #f7f7f7;
  overflow: none;
  *zoom: 1; }
  .hero:before, .hero:after {
    content: " ";
    display: table; }
  .hero:after {
    clear: both; }
  .hero img {
    width: 100%;
    max-width: 500px;
    float: right; }

.hero-inner {
  max-width: 1200px;
  margin: 0 auto;
  position: relative; }

.hero-content {
  width: 600px;
  position: absolute;
  top: 100px;
  left: 160px; }
  .hero-content p {
    font-size: 2rem; }
    .hero-content p.signature {
      color: #999;
      font-style: italic;
      margin-bottom: 4rem; }

a.button {
  background: #666;
  color: #fff;
  padding: 0.75rem 2rem;
  font-size: 1.25rem; }
