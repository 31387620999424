@charset "UTF-8";

@import 'global/variables';

@import 'utils/mixins';

@import 'global/normalise',
		'global/typography',
		'global/grid';

.brand {
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
	font-size: 3rem;
	line-height: 1;
	margin: 3rem 0 2rem;

		a {
			color: #333;
		}
}

.navigation {
	width: 100%;
	text-align: center;
	font-size: 1.5rem;
	text-transform: uppercase;
	margin-bottom: 2rem;
}

.navigation-menu {

		li {
			display: inline-block;
		}

		a {
			display: block;
			margin: 0 -2px;
			padding: 1rem;
			color: #666;
		}
}

.hero {
	background: #f7f7f7;
	@include clearfix;
		
		img {
			width: 100%;
			max-width: 500px;
			float: right;

		}
}

.hero-inner {
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
}

.hero-content {
	width: 600px;
	position: absolute;
	top: 100px;
	left: 160px;

		p {
			font-size: 2rem;
			

				&.signature {
					color: #999;
					font-style: italic;
					margin-bottom: 4rem;
				}
		}
}

a.button {
	background: #666;
	color: #fff;
	padding: 0.75rem 2rem;
	font-size: 1.25rem;
}