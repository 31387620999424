// Path alias
$assetPath:                '/content';
$uiImgPath:                '#{$assetPath}/img';

// Colors
// -------------------------
$body-font-color:           #191919 !default;

// Type
// --------------------------------------------------
// Font weights
$thin:                      100;
$light:                     300;
$regular:                   400;
$semibold:                  600;
$bold:                      700;
$thick:                     800;

$baseline:                  1.5rem !default;
$base-font-family:          "proxima-nova", sans-serif !default;
$base-font-weight:          $regular !default;
$base-font-size:            100% !default;
$base-line-height:          1.5 !default;

$body-font-family:       	$base-font-family !default;
$header-font-family:        $base-font-family !default;

// Sizes
$tera-size:                 2.8125rem !default;
$giga-size:                 2.12rem !default;
$mega-size:                 1.5rem !default;
$alpha-size:                1.25rem !default;
$beta-size:                 1rem !default;
$gamma-size:                .875rem !default;
$delta-size:                .75rem !default;
$epsilon-size:              .625rem !default;


// Layout
// --------------------------------------------------
$max-container-width:       1400px !default;
$gutter:				    30px !default;
$columns:                   12 !default;

// Shared
$border-radius:             0 !default;
$border-color:              none !default;
$border-width:              0 !default;

// Breakpoints
// --------------------------------------------------
$bp-xsmall:                 32em !default;
$bp-small:                  48em !default;
$bp-medium:                 65em !default;
$bp-large:                  $max-container-width !default;